import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "timi-login-container" }
const _hoisted_2 = { class: "timi-login-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "form" }
const _hoisted_5 = { class: "timi-form-item" }
const _hoisted_6 = { class: "formlabel" }
const _hoisted_7 = {
  key: 0,
  class: "form-error"
}
const _hoisted_8 = {
  key: 1,
  class: "form-error"
}
const _hoisted_9 = { class: "timi-form-item" }
const _hoisted_10 = { class: "formlabel" }
const _hoisted_11 = {
  key: 0,
  class: "form-error"
}
const _hoisted_12 = {
  key: 1,
  class: "form-error"
}
const _hoisted_13 = { class: "form-switch" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "form-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('label.pw_reset')), 1),
      (!_ctx.confirmed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("form", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('label.new_password')), 1),
                _createVNode(_component_PasswordInput, {
                  placeholder: _ctx.$t('label.new_password'),
                  value: _ctx.form.password,
                  onOnChange: _cache[0] || (_cache[0] = (value) => _ctx.form.password = value),
                  tabindex: 0
                }, null, 8, ["placeholder", "value"]),
                (_ctx.errorClass('password', _ctx.form.password)=='required')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('error.required')), 1))
                  : (_ctx.errorClass('password', _ctx.form.password)=='toShort')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('error.too_short')), 1))
                    : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('label.new_password_repeat')), 1),
                _createVNode(_component_PasswordInput, {
                  placeholder: _ctx.$t('label.new_password_repeat'),
                  value: _ctx.passwordRepeat,
                  onOnChange: _cache[1] || (_cache[1] = (value) => _ctx.passwordRepeat = value),
                  tabindex: 1
                }, null, 8, ["placeholder", "value"]),
                (_ctx.errorClass('passwordRepeat', _ctx.passwordRepeat)=='required')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('error.required')), 1))
                  : _createCommentVNode("", true),
                (_ctx.errorClass('passwordRepeat', _ctx.passwordRepeat)=='dontMatch')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('error.dont_match')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_Button, {
                text: _ctx.$t('button.resetPassword'),
                raised: true,
                primary: true,
                waitForResponse: _ctx.waitForResponse,
                onOnClick: _ctx.submitForm
              }, null, 8, ["text", "waitForResponse", "onOnClick"]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_router_link, { to: "/login" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('button.backToLogin')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('success.passwordChanged')), 1),
            _createVNode(_component_router_link, { to: "/login" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('button.backToLogin')), 1)
              ]),
              _: 1
            })
          ]))
    ])
  ]))
}